import Preload, { usePreload } from '../../components/PreLoad/PreLoad'
import Table from '../../components/Table/Table'
import AddToBasket from '../../components/Conversations/AddToBasket'
import { NumberFormatter } from '@mantine/core'

import backend from '../../api/backend'

const ProductsTable = ({ selected, onAddToBasket, limit }) => {
  const resolve = usePreload(backend.getProducts)

  const columns = [
    {
      title: 'Product Code',
      render: ({ ProductCode }) => <small className="rounded outline w-600">{ProductCode}</small>,
    },
    {
      title: 'Brand',
      key: 'Brand',
    },
    {
      key: 'TireType',
      title: 'Tire Type',
    },
    {
      key: 'TireLine',
      title: 'Tire Line',
    },
    {
      key: 'Size',
      title: 'Size',
    },
    {
      key: 'Description',
      title: 'Description',
    },
    {
      title: 'Discount',
      render: ({ Discount, MSRP, RetailPrice }) => (
        <NumberFormatter suffix=" %" value={((MSRP - RetailPrice) / MSRP) * 100} thousandSeparator decimalScale={2} fixedDecimalScale={false} />
      ),
    },
    {
      title: 'MSRP',
      render: ({ MSRP, currency }) => <NumberFormatter prefix={`${currency} `} value={MSRP} thousandSeparator decimalScale={2} fixedDecimalScale={false} />,
    },
    {
      title: 'Retail Price',
      render: ({ RetailPrice, currency }) => <NumberFormatter prefix={`${currency} `} value={RetailPrice} thousandSeparator decimalScale={2} fixedDecimalScale={false} />,
    },
    onAddToBasket && {
      title: 'Quantity',
      render: (product) => (
        <AddToBasket
          onPlus={onAddToBasket} //
          onMinus={onAddToBasket}
          selected={selected}
          product={product}
          canEdit={true}
        />
      ),
    },
  ].filter(Boolean)

  const { data } = resolve

  return <Preload {...resolve}>{data && <Table data={data} columns={columns} rowKey="_key" limit={limit} displayExtendedMenu={true}/>}</Preload>
}

export default ProductsTable
