import http from './http'
import settings from './settings'

class SalesApi {
  constructor() {
    this.client = http.axios
  }
  getEnquiries = () => this.client.get('/sales/enquiries')
  getEnquiriesForCustomer = (customerId) => this.client.get('/sales/enquiries/customer/' + customerId)

  getEnquiry = (id) => this.client.get('/sales/enquiries/' + id)
  deleteEnquiry = (id) => this.client.delete('/sales/enquiries/' + id)

  postEnquiry = (body) => this.client.post('/sales/enquiries', body)
  replyToEnquiry = (id, body) => this.client.put('/sales/enquiries/' + id, body)
  customerReplyToEnquiry = (id, body) => this.client.put('/sales/enquiries/' + id + '/customer-reply', body)

  getCustomerByID = (id) => this.client.get('/sales/customers/' + id)
  getCustomerInfo = (id) => this.client.get(`/sales/customers/${id}/info`)

  query = (body) => this.client.post('/sales/products/query', body)
  createOrder = (enquiryId, body) => this.client.post('/sales/enquiries/' + enquiryId + '/create-order', body)
  getOrders = () => this.client.get('/sales/orders')
  getOrder = (orderId) => this.client.get('/sales/orders/' + orderId)

  getMyAccount = () => this.client.get('./sales/accounts/my')
}

export default new SalesApi()
