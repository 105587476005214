import { useState } from 'react'
import FileSaver from 'file-saver'

import Dragon from 'components/Dragon'
import invoice from 'htmlTemplates/invoice'
import order from 'htmlTemplates/order'
import rfq from 'htmlTemplates/rfq'

import artifacts from 'api/artifacts'
import settings from 'api/settings'

const tpls = {
  invoice,
  order,
  rfq,
}

const getFooter = (title) => {
  return (
    title &&
    `<span style="font-size: 10px; margin: 0 30px;"> 
            <span style="margin-right: 20px;">${title}</span>
            <span class="pageNumber"></span>/<span class="totalPages"></span>
          </span>`
  )
}

export const usePdf = () => {
  const [isLoading, setIsLoading] = useState(false)

  const getTemplate = (name = 'invoice') => {
    const tpl = tpls[name]
    return tpl
  }

  const countTotal = (data) => {
    let subTotal = 0

    data?.basket?.forEach((item) => {
      item.total = item.quantity * item.product.RetailPrice
      subTotal += item.total
    })

    const terms = Object.entries(data?.rfqOptions).map(([name, value]) => settings.get(name, value))

    const tax = Math.ceil(subTotal * 0.15)
    const totalAmount = subTotal + tax
    return { ...data, subTotal, tax, totalAmount, terms }
  }

  const download = async (data, name = 'invoice', { title }) => {
    setIsLoading(true)

    const tpl = getTemplate(name)

    const payload = countTotal(data)

    const bodyHtml = new Dragon(tpl, payload)
    const footerHtml = getFooter(title)

    const fileData = await artifacts.downloadPdf({ bodyHtml: bodyHtml.html.innerHTML, footerHtml })
    FileSaver.saveAs(fileData, `${name}-${data._key}.pdf`)

    setIsLoading(false)
  }

  const getCompiled = (data, name = 'invoice') => {
    const tpl = getTemplate(name)
    const payload = countTotal(data)
    const bodyHtml = new Dragon(tpl, payload)
    return bodyHtml.html
  }

  return { download, getCompiled, getTemplate, isLoading, countTotal }
}
