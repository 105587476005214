import { Button, Group, Text } from '@mantine/core'
import { IconAdjustments, IconArrowsUpDown, IconDownload, IconSettings, IconChevronDown } from '@tabler/icons-react'

const ActionHeader = () => {
  return (
    <Group>
      <Group ml={0} pl={0} pr={4} pt={2} pb={2}>
        <Button variant="default" leftSection={<IconAdjustments size={12} />} size="compact-sm" radius="sm">
          <Text size="xs">Filters</Text>
        </Button>

        <Button variant="default" leftSection={<IconArrowsUpDown size={12} />} size="compact-sm" radius="sm">
          <Text size="xs">Sort</Text>
        </Button>
      </Group>

      <Group mr={0} pl={4} pr={0} pt={2} pb={2}>
        <Button variant="default" leftSection={<IconDownload size={12} />} size="compact-sm" radius="sm" rightSection={<IconChevronDown size={12} />}>
          <Text size="xs">Export</Text>
        </Button>

        <Button variant="default" size="compact-sm" radius="sm" leftSection={<IconSettings size={12} />}>
          <Text size="xs">View Settings</Text>
        </Button>
      </Group>
    </Group>
  )
}

export default ActionHeader
